@import "../variables";

.hero {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    background: url('../../../img/mountain.jpg') no-repeat center center/cover;
    background-attachment: fixed;
    overflow: hidden;

    .name__profile {
        .name {
            text-align: center;
            font-size: 25px;
            margin: 0;
            text-transform: uppercase;
            font-family: "Poppins Bold";
            text-shadow: 0px 4px 20px rgba($black, 0.5);

            @media (min-width: 374.98px) {
                font-size: 28px;
            }

            @media (min-width: 424.98px) {
                font-size: 34px;
            }
        }
    }

    .banner__profile {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 5px;

        &--left {
            width: 100%;
            margin-bottom: 15px;

            .motto {
                text-align: right;
                font-family: "Playfair Display Italic";
                font-weight: 400;
                font-size: 18px;
                color: $white;
                text-shadow: 0px 4px 20px rgba($milk, 0.5);
                margin: 0;
            }
        }

        &--middle {
            position: relative;
            display: flex;

            .photo-profile {


                img {
                    width: 190px;
                }
            }
        }

        &--right {
            width: 100%;
            margin-top: 15px;

            .navigation {
                margin: 0;
                text-align: left;
                font-family: "Playfair Display Italic";
                font-weight: 400;
                font-size: 18px;
                color: $white;
                text-shadow: 0px 4px 20px rgba($milk, 0.5);
            }

            .contact-icon {
                display: flex;
                gap: 20px;
                margin-top: 14px;

                span a {
                    font-size: 12px;
                    color: $white;
                }
            }
        }
    }

    .scroll__down {
        position: fixed;
        bottom: 10px;
        right: 20px;
        z-index: 1000;

        &--button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 8px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $white;
            color: $black;
            box-shadow: 0 4px 6px rgba(255, 255, 255, 0.2);
            animation-duration: 1.5s;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $md-max) {
    .hero {
        padding: 0px 24px;

        .name__profile {
            .name {
                font-size: 55px;
            }
        }

        .banner__profile {
            height: 432px;
            flex-direction: row;
            align-items: normal;

            &--left {
                margin-bottom: -28px;
                display: flex;
                align-items: center;

                .motto {
                    text-align: left;
                    font-size: 22px;
                }
            }

            &--middle {
                height: 100%;
                flex-direction: column;
                justify-content: flex-end;

                .photo-profile {
                    margin-left: -58px;

                    img {
                        width: 300px;
                    }
                }
            }

            &--right {
                margin-top: unset;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                gap: unset;
                margin-bottom: 30px;

                .navigation {
                    text-align: right;
                    font-size: 22px;
                }

                .contact-icon {
                    gap: 40px;
                    margin-top: 30px;
                    margin-left: -50px;

                    span a {
                        font-size: 14px;
                    }
                }
            }
        }

        .scroll__down {
            &--button {
                font-size: 12px;
                width: 38px;
                height: 38px;
            }
        }
    }
}

@media (min-width: 1359.98px) {
    .hero {
        .name__profile {
            .name {
                font-size: 110px;
            }
        }

        .banner__profile {
            height: 500px;

            &--left {
                margin-bottom: 100px;

                .motto {
                    font-size: 30px;
                }
            }

            &--middle {
                .photo-profile {

                    img {
                        width: 390px;
                    }
                }
            }

            &--right {
                margin-bottom: 90px;

                .navigation {
                    font-size: 30px;
                }

                .contact-icon {
                    margin-top: 46px;
                    margin-left: -190px;

                    span a {
                        font-size: 14px;
                    }
                }
            }
        }

        .scroll__down {
            &--button {
                font-size: 8px;
                width: 40px;
                height: 40px;
            }
        }
    }
}
