@font-face {
    font-family: 'Playfair Display Italic';
    src: url('../fonts/PlayfairDisplay-MediumItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display Extra Bold';
    src: url('../fonts/PlayfairDisplay-ExtraBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('../fonts/Poppins-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins Medium';
    src: url('../fonts/Poppins-Medium.ttf') format('truetype');
    font-display: swap;
}