@import "variables";
@import "./fonts";


* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: $black;
  color: $white;
}

a {
  text-decoration: none;
}
